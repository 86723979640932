
require.register("react-google-maps/lib/withGoogleMap.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-google-maps");
  (function() {
    "use strict"

Object.defineProperty(exports, "__esModule", {
  value: true,
})

var _objectWithoutProperties2 = require("babel-runtime/helpers/objectWithoutProperties")

var _objectWithoutProperties3 = _interopRequireDefault(
  _objectWithoutProperties2
)

var _defineProperty2 = require("babel-runtime/helpers/defineProperty")

var _defineProperty3 = _interopRequireDefault(_defineProperty2)

var _getPrototypeOf = require("babel-runtime/core-js/object/get-prototype-of")

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf)

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck")

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2)

var _createClass2 = require("babel-runtime/helpers/createClass")

var _createClass3 = _interopRequireDefault(_createClass2)

var _possibleConstructorReturn2 = require("babel-runtime/helpers/possibleConstructorReturn")

var _possibleConstructorReturn3 = _interopRequireDefault(
  _possibleConstructorReturn2
)

var _inherits2 = require("babel-runtime/helpers/inherits")

var _inherits3 = _interopRequireDefault(_inherits2)

var _bind2 = require("lodash/bind")

var _bind3 = _interopRequireDefault(_bind2)

exports.withGoogleMap = withGoogleMap

var _warning = require("warning")

var _warning2 = _interopRequireDefault(_warning)

var _invariant = require("invariant")

var _invariant2 = _interopRequireDefault(_invariant)

var _recompose = require("recompose")

var _propTypes = require("prop-types")

var _propTypes2 = _interopRequireDefault(_propTypes)

var _react = require("react")

var _react2 = _interopRequireDefault(_react)

var _constants = require("./constants")

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

/* global google */
function withGoogleMap(BaseComponent) {
  var factory = _react2.default.createFactory(BaseComponent)

  var Container = (function(_React$PureComponent) {
    ;(0, _inherits3.default)(Container, _React$PureComponent)

    function Container() {
      var _ref

      var _temp, _this, _ret

      ;(0, _classCallCheck3.default)(this, Container)

      for (
        var _len = arguments.length, args = Array(_len), _key = 0;
        _key < _len;
        _key++
      ) {
        args[_key] = arguments[_key]
      }

      return (
        (_ret = ((_temp = ((_this = (0, _possibleConstructorReturn3.default)(
          this,
          (_ref =
            Container.__proto__ ||
            (0, _getPrototypeOf2.default)(Container)).call.apply(
            _ref,
            [this].concat(args)
          )
        )),
        _this)),
        (_this.state = {
          map: null,
        }),
        (_this.handleComponentMount = (0, _bind3.default)(
          _this.handleComponentMount,
          _this
        )),
        _temp)),
        (0, _possibleConstructorReturn3.default)(_this, _ret)
      )
    }

    ;(0, _createClass3.default)(Container, [
      {
        key: "getChildContext",
        value: function getChildContext() {
          return (0, _defineProperty3.default)(
            {},
            _constants.MAP,
            this.state.map
          )
        },
      },
      {
        key: "componentWillMount",
        value: function componentWillMount() {
          var _props = this.props,
            containerElement = _props.containerElement,
            mapElement = _props.mapElement

          ;(0, _invariant2.default)(
            !!containerElement && !!mapElement,
            "Required props containerElement or mapElement is missing. You need to provide both of them.\n The `google.maps.Map` instance will be initialized on mapElement and it's wrapped by containerElement.\nYou need to provide both of them since Google Map requires the DOM to have height when initialized."
          )
        },
      },
      {
        key: "handleComponentMount",
        value: function handleComponentMount(node) {
          if (this.state.map || node === null) {
            return
          }
          ;(0, _warning2.default)(
            "undefined" !== typeof google,
            "Make sure you've put a <script> tag in your <head> element to load Google Maps JavaScript API v3.\n If you're looking for built-in support to load it for you, use the \"async/ScriptjsLoader\" instead.\n See https://github.com/tomchentw/react-google-maps/pull/168"
          )
          // https://developers.google.com/maps/documentation/javascript/3.exp/reference#Map
          var map = new google.maps.Map(node)
          this.setState({ map: map })
        },
      },
      {
        key: "render",
        value: function render() {
          var _props2 = this.props,
            containerElement = _props2.containerElement,
            mapElement = _props2.mapElement,
            restProps = (0, _objectWithoutProperties3.default)(_props2, [
              "containerElement",
              "mapElement",
            ])
          var map = this.state.map

          if (map) {
            return _react2.default.cloneElement(
              containerElement,
              {},
              _react2.default.cloneElement(mapElement, {
                ref: this.handleComponentMount,
              }),
              _react2.default.createElement("div", null, factory(restProps))
            )
          } else {
            return _react2.default.cloneElement(
              containerElement,
              {},
              _react2.default.cloneElement(mapElement, {
                ref: this.handleComponentMount,
              }),
              _react2.default.createElement("div", null)
            )
          }
        },
      },
    ])
    return Container
  })(_react2.default.PureComponent)

  Container.displayName =
    "withGoogleMap(" + (0, _recompose.getDisplayName)(BaseComponent) + ")"
  Container.propTypes = {
    containerElement: _propTypes2.default.node.isRequired,
    mapElement: _propTypes2.default.node.isRequired,
  }
  Container.childContextTypes = (0, _defineProperty3.default)(
    {},
    _constants.MAP,
    _propTypes2.default.object
  )

  return Container
}

exports.default = withGoogleMap
  })();
});