
require.register("react-google-maps/lib/utils/OverlayViewHelper.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-google-maps");
  (function() {
    "use strict"

Object.defineProperty(exports, "__esModule", {
  value: true,
})

var _isFunction2 = require("lodash/isFunction")

var _isFunction3 = _interopRequireDefault(_isFunction2)

exports.getOffsetOverride = getOffsetOverride
exports.getLayoutStyles = getLayoutStyles

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

/* global google */
function getOffsetOverride(containerElement, props) {
  var getPixelPositionOffset = props.getPixelPositionOffset
  //
  // Allows the component to control the visual position of the OverlayView
  // relative to the LatLng pixel position.
  //

  if ((0, _isFunction3.default)(getPixelPositionOffset)) {
    return getPixelPositionOffset(
      containerElement.offsetWidth,
      containerElement.offsetHeight
    )
  } else {
    return {}
  }
}

function createLatLng(inst, Type) {
  return new Type(inst.lat, inst.lng)
}

function createLatLngBounds(inst, Type) {
  return new Type(
    new google.maps.LatLng(inst.ne.lat, inst.ne.lng),
    new google.maps.LatLng(inst.sw.lat, inst.sw.lng)
  )
}

function ensureOfType(inst, type, factory) {
  if (inst instanceof type) {
    return inst
  } else {
    return factory(inst, type)
  }
}

function getLayoutStylesByBounds(mapCanvasProjection, offset, bounds) {
  var ne = mapCanvasProjection.fromLatLngToDivPixel(bounds.getNorthEast())
  var sw = mapCanvasProjection.fromLatLngToDivPixel(bounds.getSouthWest())
  if (ne && sw) {
    return {
      left: sw.x + offset.x + "px",
      top: ne.y + offset.y + "px",
      width: ne.x - sw.x - offset.x + "px",
      height: sw.y - ne.y - offset.y + "px",
    }
  }
  return {
    left: "-9999px",
    top: "-9999px",
  }
}

function getLayoutStylesByPosition(mapCanvasProjection, offset, position) {
  var point = mapCanvasProjection.fromLatLngToDivPixel(position)
  if (point) {
    var x = point.x,
      y = point.y

    return {
      left: x + offset.x + "px",
      top: y + offset.y + "px",
    }
  }
  return {
    left: "-9999px",
    top: "-9999px",
  }
}

function getLayoutStyles(mapCanvasProjection, offset, props) {
  if (props.bounds) {
    var bounds = ensureOfType(
      props.bounds,
      google.maps.LatLngBounds,
      createLatLngBounds
    )
    return getLayoutStylesByBounds(mapCanvasProjection, offset, bounds)
  } else {
    var position = ensureOfType(
      props.position,
      google.maps.LatLng,
      createLatLng
    )
    return getLayoutStylesByPosition(mapCanvasProjection, offset, position)
  }
}
  })();
});