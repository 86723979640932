
require.register("babel-runtime/helpers/classCallCheck.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "babel-runtime");
  (function() {
    "use strict";

exports.__esModule = true;

exports.default = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};
  })();
});