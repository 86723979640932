
require.register("can-use-dom/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "can-use-dom");
  (function() {
    var canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

module.exports = canUseDOM;
  })();
});